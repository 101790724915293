<template>
  <section class="initial-menu">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container mt-5">
        <div class="row no-row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="card_box">
              <h2 class="mb-5">
                {{ 'Menú de ' + selected_bot.bot_name }}
              </h2>
              <validationchat />
              <validationbot />
              <div>
                <label>Mensaje de bienvenida</label>
                <p>
                  Considera que los mensajes de bienvenida se suman, es decir,
                  aparecerán todos al inicio de la conversación
                </p>
                <div
                  v-for="(message, index) in welcomeVariants"
                  :key="message.id"
                  class="delete-input"
                >
                  <input
                    type="text"
                    :class="{
                      input_error:
                        message.text == 0 && error.empty,
                    }"
                    v-model="message.text"
                    :placeholder="placeholderMessage[index]"
                  />
                  <button
                    v-if="welcomeVariants && welcomeVariants.length > 1"
                    @click="deleteWelcomeVariation(index)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </div>
                <button
                  @click="addWelcomeVariant()"
                  class="btn btn-dash btn-dash-sm m-0 mt-2"
                  v-if="welcomeVariants.length < 3"
                >
                  +
                </button>
                <FadeTransition :duration="200">
                  <div class="body_box">
                    <div class="label-box mt-5 mb-4">
                      <label class="mb-0">Opciones del menú</label>
                      <span v-if="!loading_options" class="mb-0"><span>{{ menuOptions.length }}</span> / 6</span>
                    </div>
                    <SpinnerLoading v-if="loading_options" />
                    <FadeTransition v-else :duration="200" v-show="!loading_options">
                      <div>
                        <RecursiveInitialMenuOptions
                          :options="menuOptions"
                          :key="keyCounter"
                        />
                        <button
                          @click="addOptions()"
                          v-if="menuOptions.length < 6"
                          class="btn btn-dash btn-dash-sm m-0 mt-2"
                        >
                          +
                        </button>
                      </div>
                    </FadeTransition>
                    <div class="d-flex justify-content-between align-items-center my-5">
                      <button
                        class="btn btn-base"
                        @click.prevent="saveInitialMenu"
                        :disabled="loadingMenu || view_permissions.edit != true"
                      >
                        <template v-if="loadingMenu">
                          <i class="fas fa-spinner fa-spin"></i> Guardando...
                        </template>
                        <template class="btn-act" v-else>
                          Guardar
                          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
                        </template>
                      </button>
                    </div>
                  </div>
                </FadeTransition>
              </div>
            </div>
          </div>
          <!-- Columna preview -->
          <div class="col-lg-5 p-0">
            <div class="card card-chat pt-0">
              <div class="card-body">
                {{ chatbot.trama_url }}
                <Chatbot
                  v-if="chatbot"
                  :chatbot__trama="chatbot.trama_url"
                  :chatbot__logo="chatbot.bot_logo_url"
                  :chatbot__nombre="selected_bot.bot_name"
                  :chatbot__color__principal="chatbot.bot_title_bar_bg_color"
                  :chatbot__color__secundario="chatbot.bot_title_color"
                  :chatbot__bot__color__fondo="chatbot.bot_box_bg_color"
                  :chatbot__bot__color__letra="chatbot.bot_box_font_color"
                  :chatbot__user__color__fondo="chatbot.user_box_bg_color"
                  :chatbot__user__color__letra="chatbot.user_box_font_color"
                  :accessibility="chatbot.accessibility"
                  :bubble="chatbot.img_bubble"
                  :chatbot_menu="true"
                  :welcomes="welcomeVariants"
                  :options="menuOptions"
                  :buttons="submenuOptions"
                  :placeholderMessage="placeholderMessage"
                  :placeholderOptions="placeholderOptions"
                >
                </Chatbot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validationchat from './components/validationchat.vue';
import validationbot from './components/validationbot.vue';
import Chatbot from '@/components/Chatbot.vue';
import dashboard_api from '@/dashboard_api.js';
import { FadeTransition } from 'vue2-transitions';
import RecursiveInitialMenuOptions from '@/components/RecursiveInitialMenuOptions.vue';

export default {
  components: {
    validationchat,
    validationbot,
    FadeTransition,
    Chatbot,
    RecursiveInitialMenuOptions,
  },
  data() {
    return {
      intents: [],
      loadingMenu: false,
      loading: true,
      chatbot: {
        id: '',
        name: '',
        bot_logo_url: '',
        trama_url: '',
        bot_title: '',
        user_box_bg_color: '#ffffff',
        user_box_font_color: '#525252',
        bot_box_bg_color: '#D8EEFF',
        bot_box_font_color: '#ffffff',
        bot_title_bar_bg_color: '#333',
        bot_title_color: '#ffffff',
        bot_name: '',
        option_bg_color: '#ffffff',
        option_font_color: '#525252',
        web: '',
        rolagente: [],
        template: '',
        type_bot: false,
        notification: false,
        message_end: '',
        accessibility: false,
        img_bubble: false,
        menu: false,
        desktop: false,
        mobile: false,
        init_conver: true,
        init_conver_mobile: true,
      },
      welcomeVariants: [
        {
          text: '',
        },
      ],
      menuOptions: [
        {
          label: '',
          selectedOption: '',
          value: {
            input: {
              text: '',
            },
          },
          options: [],
        },
      ],
      submenuOptions: [],
      error: {
        name: '',
        web: '',
        empty: '',
      },
      placeholderMessage: [
        'Bienvenido, estoy en línea, ¿Cómo puedo ayudarte?',
        '¡Hola!, estoy en línea, ¿En qué te puedo ayuda?',
        '¡Bienvenido!, estoy en línea, ¿En qué necesitas ayuda?',
      ],
      suboptionButton: ['answer', 'url'],
      optionButton: ['answer', 'buttons', 'url'],
      placeholderOptions: [
        '🔩 Opciones',
        '🔥 Opciones',
        '🛠️ Opciones',
        '⚙️ Opciones',
        '🧰 Opciones',
        '🪛 Opciones',
        '📍 Opciones',
        '🧑‍💻 Opciones',
      ],
      keyCounter: 0, // Solo para refrescar y validar mensajes de error
      loading_options: false
    };
  },
  mounted() {
    this.initializeInitialMenu();
  },
  computed: {
    ...mapState([
      'user',
      'selected_bot',
      'ownerBots',
      'plan_name',
      'skill_template',
      'view_permissions'
    ]),
    bot_id() {
      return this.selected_bot.id;
    },
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getChatbotEdit();
      },
    },
  },
  methods: {
    ...mapMutations(['editSelectedBot', 'setTemplateValue']),

    async initializeInitialMenu() {
      console.log('TEMPLATE: ', this.skill_template);
      // getting intents
      this.intents = await this.$store.dispatch(
        'dtClientIntentsModule/listByBotId',
        this.bot_id,
      );
      console.log('THIS INTENTS-----> ', this.intents);
      // filter only intents which start with FAQ. and CUSTOM.
      let unicaInternaMostrar = "INTERNA.contacto_ejecutiva";
      let regexToTest = /INTERNA.contacto_ejecutiva/g;
      if (this.intents && this.intents.length) {
        this.intents = this.intents.filter(
          (intent) =>
            regexToTest.test(intent.intent_name) ||
            intent.intent_name.startsWith('FAQ.') ||
            intent.intent_name.startsWith('CUSTOM.'),
        );
      }
      // get welcome texts
      const WELCOME_INTENT_NAME = 'INICIO'; // forever, dont change
      const WELCOME_INTENT_BUTTONS_NAME = 'MENÚ'; // forever, dont change
      const TEMPLATE_NAME = this.skill_template.value;
      // console.log('🚀 Aqui *** -> skill_template:', this.skill_template);
      const welcomeNode = await this.$store.dispatch(
        'dtClientNodesModule/listByIntentName',
        {
          intentName: WELCOME_INTENT_NAME,
          botId: this.bot_id,
          template: this.skill_template.value,
        },
      );
      console.log('WELCOME NODE -->', welcomeNode);
      if (welcomeNode.output) {
        this.welcomeVariants = welcomeNode.output.generic[0].values;
        // get buttons
        const welcomeNodeButtons = await this.$store.dispatch(
          'dtClientNodesModule/listByIntentName',
          {
            intentName: WELCOME_INTENT_BUTTONS_NAME,
            botId: this.bot_id,
            template: this.skill_template.value,
          },
        );
        let menuOptions = welcomeNodeButtons.output.generic[0].options;
        // adding submenuOptions
        menuOptions = menuOptions.map((el) => ({
          ...el,
          options: [],
        }));
        // get data for each button
        this.loading_options = true;
        for (let option of menuOptions) {
          option = await this.getOptionData(option);
        };
        if (menuOptions?.length > 0) {
          this.menuOptions = menuOptions.slice();
          this.loading_options = false;
        };
        this.loading = false;
        // this.menuOptions.forEach(async (option, index) => {
        //   this.menuOptions[index] = await this.getOptionData(option);
        // });
      }

      this.loading_options = false;
    },

    async getOptionData(option) {
      const TEMPLATE_NAME = this.skill_template;
      console.log('🚀 Aqui *** -> template_name:', TEMPLATE_NAME);
      console.log('🚀 Aqui *** -> option:', option);
      const textValue = option.value.input.text;
      if (textValue.startsWith('boton_')) {
        // get node by intent name
        const node = await this.$store.dispatch(
          'dtClientNodesModule/listByIntentName',
          {
            intentName: textValue,
            botId: this.bot_id,
            template: this.skill_template.value,
          },
        );
        if (node) {
          console.log('🚀 Aqui *** -> node.output:', node.output);
          console.log('🚀 Aqui *** -> textValue:', textValue);
          // check type
          option['action'] = node.next_step;
          console.log('🚀 Aqui *** -> node.next_step:', node.next_step);
          // testing other workaround //
          // switch (option['action']) {
          //  case 'answer': {
          //    break;
          //  }
          //  case 'buttons':
          //    break;
          //  case 'url':
          //    break;
          //  default:
          //    break;
          // }

          // in case of answer, get node associated
          if (option['action'] === 'answer') {
            let intentAssociated = await this.$store.dispatch(
              'dtClientNodesModule/getIntentAssociated',
              {
                nodeId: node.jump_to,
                bot_id: this.bot_id,
              },
            );
            if (intentAssociated.status === true) {
              option['selectedIntent'] = this.intents.find(
                (el) => el.intent_ui_name.toLowerCase() === intentAssociated.intent.toLowerCase(),
              );
            } else {
              option['selectedIntent'] = '';
            }
          }
          if (option['action'] === 'buttons') {
            console.log('🚀 Aqui *** -> suboption:', node);
            let subOptions = node.output.generic.find(
              (el) => el.response_type === 'option',
            );
            subOptions = subOptions ? subOptions.options : [];
            if (subOptions) {
              // get data for each button
              // subOptions.forEach(async (option, index) => {
              //   subOptions[index] = await this.getOptionData(option);
              // });
              for (let subOption of subOptions) {
                subOption = await this.getOptionData(subOption);
              }
            }
            // push every suboption
            option['options'] = subOptions;
          }
        } else {
          console.log('Node not found: ', textValue);
        }
      } else {
        // case for url
        option['action'] = 'url';
        option['url'] = textValue;
      }
      return option;
    },
    addOptions() {
      console.log("HEEERE: ", this.intents);
      this.menuOptions.push({
        label: '',
        action: '',
        value: {
          input: {
            text: '',
          },
        },
        options: [],
      });
    },
    addWelcomeVariant() {
      this.welcomeVariants.push({
        text: '',
      });
    },
    deleteWelcomeVariation(index) {
      this.$swal({
        title: '¿Eliminar variación?',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          this.welcomeVariants.splice(index, 1);
        }
      });
    },
    // Recursivo para mostrar error en inputs con label repetido
    setErrorMessages(array, compareArray, value) {
      console.log("array->", array);
      console.log("value->", value);
      if (array && array.length > 0) {
        array.forEach(el => {
          if (compareArray.includes(el.label)) {
            el.duplicate = value;
          }
          if (el.duplicate) {
            el.duplicate = value;
          }
          if (el.empty_label) {
            el.empty_label = false;
          }
          if (el.options && el.options.length > 0) {
            this.setErrorMessages(el.options, compareArray, value);
          }
        });
      }
      this.keyCounter++;
    },
    // Recursivo
    getOptionsNames(array, newArray, secondArray) {
      array.forEach(el => {
        if (el.label && el.label.length > 0) {
          // tomar todos los label name del options array
          newArray.push(el.label.trim());
        }
        if (el.label.length == 0) {
          // tomar todos los options sin label
          secondArray.push(el);
        }
        if (el.options && el.options.length > 0) {
          this.getOptionsNames(el.options, newArray, secondArray);
        }
      });
    },
    async saveInitialMenu() {
      this.loadingMenu = true;

      let names = [];
      let no_names = [];
      let filtered_duplicates = [];

      // Nuevo array con total de nombres
      for (let item_option of this.menuOptions) {
        if (item_option.label?.length > 0) names.push(item_option.label.trim());
        if (!item_option.label) no_names.push(item_option);
        if (item_option.options?.length > 0) this.getOptionsNames(item_option.options, names, no_names);
      }
      console.log("names->", names);
      console.log("no names->", no_names);
      // Nuevo array con nombres repetidos
      if (names?.length > 0) {
        filtered_duplicates = names.filter((el, index, array) => {
          return array.filter(ele => {
            return el == ele;
          }).length > 1;
        });
      }
      console.log("no_names?.length > 0->", no_names?.length > 0);
      console.log("filtered_duplicates?.length > 0->", filtered_duplicates?.length > 0);
      console.log("filtered_duplicates->", filtered_duplicates);

      if (no_names?.length > 0) {
        no_names.forEach(el => {
          el.empty_label = true;
        });
        this.$swal({
          title: 'Completa los nombres de las opciones.',
          icon: 'info',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
        this.keyCounter++;
      } else if (filtered_duplicates?.length > 0) {
        // Agregando param duplicate boolean para activar mensaje de error en campos que correspondan
        this.menuOptions.forEach(el => {
          if (filtered_duplicates.includes(el.label)) el.duplicate = true;
          if (el.empty_label) el.empty_label = false;
          if (el.options && el.options.length > 0) this.setErrorMessages(el.options, filtered_duplicates, true);
        });
        this.$swal({
          title: 'Existen nombres duplicados.',
          icon: 'info',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      } else {
        names = [];
        filtered_duplicates = [];

        this.menuOptions.forEach(el => {
          el.duplicate = false;

          if (el.options?.length > 0) this.setErrorMessages(el.options, filtered_duplicates, false);
        });
        this.keyCounter = 0;
        console.log("menuOptions->", this.menuOptions);

        await Promise.all([
          this.$store.dispatch('dtClientIntentsModule/intentComplete', {
            template: this.skill_template.value,
            bot_id: this.bot_id,
          }),
          this.$store.dispatch('dtClientNodesModule/nodeComplete', {
            template: this.skill_template.value,
            bot_id: this.bot_id,
          }),
          this.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
            template: this.skill_template.value,
            bot_id: this.bot_id,
          }),
        ]);
        // saving texts
        await this.$store.dispatch('dtClientNodesModule/updateWelcomeTexts', {
          answers: this.welcomeVariants.map((el) => el.text),
          bot_id: this.bot_id,
          template: this.skill_template.value,
          buttons: this.menuOptions.map((el) => ({
            text: el.label,
            type: el.action,
            url: el.url ? el.url : null,
          })),
        });
        const formattedOptions = await this.formatInitialMenuOptions(
          this.menuOptions,
        );
        let result = await this.$store.dispatch(
          'dtClientNodesModule/updateWelcomeButtons',
          {
            template: this.skill_template.value,
            buttons: formattedOptions,
            bot_id: this.bot_id,
          },
        );
        console.log('---------------> RESULT: ', result);
        if (result.msg === 'error-botones') {
          this.loadingMenu = false;
          return this.$swal({
            icon: 'error',
            title: 'Existen botones duplicados',
            text: 'Los botones no pueden tener el mismo texto',
            timer: 4000,
            showConfirmButton: false,
          });
        }
        this.$swal({
          icon: 'success',
          title: 'Menú Inicial actualizado',
          timer: 1000,
          showConfirmButton: false,
        });
        this.initializeInitialMenu();
      }
      this.loadingMenu = false;
    },
    async getChatbotEdit() {
      let self = this;
      self.chatbot = (
        await dashboard_api.get(`/bot/showedit/${self.bot_id}`)
      ).data;

      const businessType = (
        await dashboard_api.get(`/get_bot_info/${self.chatbot.id}`)
      ).data.business_type;
      self.custom = businessType.show;
      // inicializando horarios comerciales
      if (self.showSchedule) {
        dashboard_api.get(`schedule/list/${self.chatbot.id}`).then((res) => {
          for (const schedule of res.data) {
            const formatted = self.formatSchedule(schedule);
            self.addSchedule(
              schedule.id,
              formatted.days,
              formatted.hourFrom,
              formatted.hourTo,
            );
          }
        });
      }
    },
    async formatInitialMenuOptions(options) {
      const buttonButtons = options.filter((el) => el.action === 'buttons');
      const promisesButtonButtons = buttonButtons.map(async (buttonButton) => {
        buttonButton.options = await this.formatInitialMenuOptions(
          buttonButton.options,
        );
        return buttonButton;
      });

      await Promise.all(promisesButtonButtons);
      const answerButtons = options.filter((el) => el.action === 'answer');
      // get nodes by intent name for answerButtons
      const promises = await Promise.all(
        answerButtons.map((button, index) =>
          this.$store.dispatch('dtClientNodesModule/listByIntentName', {
            intentName: button.selectedIntent.intent_name,
            botId: this.bot_id,
            template: this.skill_template.value,
          }),
        ),
      );
      // asign each promise to each button
      answerButtons.forEach((button, index) => {
        if (promises[index].id) {
          button.jump_to = promises[index].id;
        }
      });
      // last format to buttons
      options.forEach((option, index) => {
        if (option.action === 'answer') {
          options[index] = {
            text: option.label,
            action: option.action,
            jump_to: option.jump_to,
          };
        }
        if (option.action === 'buttons') {
          options[index] = {
            text: option.label,
            action: option.action,
            options: option.options,
          };
        }
        if (option.action === 'url') {
          options[index] = {
            text: option.label,
            action: option.action,
            url: option.url,
          };
        }
      });
      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
.initial_menu {
  .no-row {
    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-lg-5,
  .col-lg-6 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .card_box {
    padding: 0 0 0 3rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
  }
  select {
    width: 100%;
  }
  .btn-base {
    margin: 0;
    padding: 0.5rem;
    width: 25%;

    @media (max-width: 1600px) {
      width: 40%;
    }
    @media (max-width: 1279px) {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
  .card {
    padding: 1.5rem;
    border-color: #cdcdcd;
  }
  .info_box {
    @media (max-width: 1600px) {
      margin: 1.25rem auto;
    }
  }
  .delete-input {
    display: flex;
    justify-content: space-between;
  }
}
.body_box {
  .bg-spinner {
    position: relative !important;
    width: 100%;
    height: 190px !important;
    // background-color: #fff !important;
    border-radius: 1rem;
    border: 1px solid #e8e8e8;
  }
}
</style>
